const lastBackgroundColor = localStorage.getItem("lastBackgroundColor");
const loadingScreen = document.getElementById("loadingScreen");
if (lastBackgroundColor) {
  document.body.style.backgroundColor = lastBackgroundColor;
  if (loadingScreen) {
    loadingScreen.style.backgroundColor = lastBackgroundColor;
  }
}

document.querySelectorAll('.backgroundButtonColor').forEach(item => {
  item.addEventListener("click", function(e) {
    document.body.style.backgroundColor = e.target.style.backgroundColor;
    localStorage.setItem("lastBackgroundColor", e.target.style.backgroundColor);
  });
})