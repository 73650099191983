import * as THREE from 'three'
import { OrbitControls } from './OrbitControls.js'

import * as dat from 'lil-gui'
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import { MTLLoader } from 'three/addons/loaders/MTLLoader.js';

import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPixelatedPass } from 'three/examples/jsm/postprocessing/RenderPixelatedPass.js';

// Scene
const scene = new THREE.Scene()

const lupoObjLoader = new OBJLoader()
const mtlLoader = new MTLLoader();

// Create skybox solid color
scene.background = new THREE.Color(0x300090); 

// Loading models and put them into scene
mtlLoader.load('/models/Games/wii2.mtl', function (materials) {
    materials.preload();
    lupoObjLoader.setMaterials(materials);
    
    // Modify all loaded materials to set pixelated texture filtering
    for (const material of Object.values(materials.materials)) {
        // material.map = textureLoader.load('texture.png');
        material.map.magFilter = THREE.NearestFilter;
        material.map.minFilter = THREE.NearestFilter;
    }

    lupoObjLoader.load(
        // resource URL
        '/models/Games/wii2.obj',
        // called when resource is loaded
        function ( object ) {
            scene.add( object );
        },
    );
});

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.8)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.camera.left = - 7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(- 5, 5, 0)
scene.add(directionalLight)

/**
 * Sizes
 */
/**
 * Sizes
 */
const PIXEL_SIZE = window.innerWidth < 420 ? 3.5 : 4.5;
const initialPixelSizeSmall = window.innerWidth < 420;

const sizes = {
    width: window.innerWidth <= 420 ? 320 : 400,
    height: window.innerWidth <= 420 ? 320 : 400,
}

window.addEventListener('resize', () =>
{
    if (window.innerWidth <= 420 && sizes.width !== 320) {
        sizes.width = 320
        sizes.height = 320
        renderer1.setSize(sizes.width, sizes.height)
        composer1.removePass(renderPixelatedPass1);
        renderPixelatedPass1.pixelSize = 4;
        composer1.addPass(renderPixelatedPass1);
    } else if (window.innerWidth > 420 && sizes.width !== 400) {
        sizes.width = 400
        sizes.height = 400
        renderer1.setSize(sizes.width, sizes.height)
        composer1.removePass(renderPixelatedPass1);
        renderPixelatedPass1.pixelSize = initialPixelSizeSmall ? 3.5 : 4.;
        composer1.addPass(renderPixelatedPass1);
}
})
/**
 * Camera
 */
// Canvas
const canvas1 = document.querySelector('canvas.videoGames1')

// Base camera
const camera1 = new THREE.PerspectiveCamera(75, (sizes.width - 50) / sizes.height, 0.1, 100)
camera1.position.set(1.5, 1.5, 1.5)
scene.add(camera1)

// Controls
const controls1 = new OrbitControls(camera1, canvas1)
controls1.target.set(0, 0.5, 0)
controls1.enableDamping = true
controls1.maxPolarAngle = 1.4
controls1.minDistance = 0.7
controls1.maxDistance = 3.0
controls1.enablePan = false
controls1.enableZoom = false

/**
 * Floor
 */
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(10, 10),
    new THREE.MeshStandardMaterial({
        color: '#444444',
        metalness: 0,
        roughness: 0.5
    })
)
floor.receiveShadow = true
floor.rotation.x = - Math.PI * 0.5
scene.add(floor)

/**
 * Renderer
 */
const renderer1 = new THREE.WebGLRenderer({
    canvas: canvas1,
})
const context1 = renderer1.getContext();

context1.canvas.addEventListener('webglcontextlost', event => {
    canvas1.style.zIndex = 0;
}, false);

const renderers = [renderer1]

renderers.forEach((r, i) => {
    r.shadowMap.enabled = true
    r.shadowMap.type = THREE.PCFSoftShadowMap
    r.setSize(sizes.width, sizes.height)
    r.setPixelRatio(1)
})

const pixelPassOptions =  {
    normalEdgeStrength: 0,
    depthEdgeStrength: 5
};
const renderPixelatedPass1 = new RenderPixelatedPass(PIXEL_SIZE, scene, camera1, pixelPassOptions);
const composer1 = new EffectComposer(renderer1);
composer1.addPass(renderPixelatedPass1);

/**
 * Animate
 */
const tick = () =>
{
    // Update controls
    controls1.update()

    // Render
    composer1.render();

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()