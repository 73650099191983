let scroll = window.scrollY;

const content = document.getElementById("content");

content.addEventListener("mouseup", function(e) {
  const heart = document.createElement("div");
  heart.classList.add("heart");
  heart.innerHTML = `<img src="./images/heart.png" alt="heart" height="50" />`;
  heart.style.left = e.clientX - 25 + "px";
  heart.style.top = e.clientY + scroll - 25 + "px";
  heart.style.zIndex = 99999999;
  document.body.appendChild(heart);
  setTimeout(() => heart.remove(), 1000);
});

window.addEventListener("scroll", (event) => {
  scroll = window.scrollY;
});

const text = document.querySelector('.text');
const textString = text.innerHTML;
text.innerHTML = '';

let loadingFinished = false;

let i = 0;
text.style.opacity = 1;
const timer = setInterval(() => {
  if (i < textString.length) {
    text.innerHTML += textString.charAt(i);
    i++;
  } else {
    clearInterval(timer);
    const infoText = document.getElementById("loadingTextinfo");
    setTimeout(() => {
      infoText.style.opacity = 0.6;
      loadingFinished = true;
    }, 200);
  }
}, 140);

const loadingScreen = document.getElementById("loadingScreen");

loadingScreen.addEventListener("click", function(e) {
  const heart = document.createElement("div");
  heart.classList.add("heart");
  heart.innerHTML = `<img src="./images/heart.png" alt="heart" height="80"/>`;
  heart.style.position = "fixed";
  heart.style.left = e.clientX - 40 + "px";
  heart.style.top = e.clientY - 40 + "px";
  heart.style.zIndex = 99999999;
  document.body.appendChild(heart);
  setTimeout(() => heart.remove(), 1000);

  if (loadingScreen && loadingFinished) {
    loadingScreen.style.opacity = 0;
    setTimeout(() => {
      document.body.style.overflowY = "scroll";
      loadingScreen.remove()
    }, 1500);
  }
});
