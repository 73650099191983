const envelope = document.getElementById("envelope");
const envelopeDoor = document.getElementById("envelopeDoor");
const envelopeDoorGray = document.getElementById("envelopeDoorGray");
const envelopeTop = document.getElementById("envelopeTop");
const envelopeBottom = document.getElementById("envelopeBottom");
const letter = document.getElementById("letter");

let saturationAmount = 1;
let letterVisible = false;

envelope.addEventListener("click", function(e) {
  saturationAmount -= 0.2;
  if (saturationAmount > 0.2) {
    envelopeDoorGray.style.opacity = saturationAmount;
  } else if (saturationAmount <= 0.2 && !letterVisible) {
    letterVisible = true;
    envelopeDoorGray.remove();
    envelopeDoor.style.animationPlayState = 'running'
    envelopeTop.style.animationPlayState = 'running'
    envelopeBottom.style.animationPlayState = 'running'
    letter.style.animationPlayState = 'running'
    envelope.style.animationPlayState = 'running'
  }
});
