import * as THREE from 'three'
import { OrbitControls } from './OrbitControls.js'

import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import { MTLLoader } from 'three/addons/loaders/MTLLoader.js';

import * as CANNON from 'cannon-es'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPixelatedPass } from 'three/examples/jsm/postprocessing/RenderPixelatedPass.js';

const linksWrapper = document.getElementById("linksWrapper");
const canvas = document.querySelector('canvas.links')
let renderer;
let cameraUp = false;

const PIXEL_SIZE = 4.5;

    const createRandomBox = (type) =>
    {
        THREE.Euler
        createBox(
            1.0,
            1.0,
            0.3,
            {
                x: (Math.random() - 0.5) * 2,
                y: 3,
                z: (Math.random() - 0.5) * 2
            },
            type
        )
    }

    // Reset
    const reset = () =>
    {
        for(const object of objectsToUpdate)
        {
            // Remove body
            world.removeBody(object.body)

            // Remove mesh
            scene.remove(object.mesh)
        }
        
        objectsToUpdate.splice(0, objectsToUpdate.length)
    }

    document.getElementById("showBoxes").addEventListener("click", function(e) {
        const arrow = document.getElementById("bottomArrowWrapper");
        if (arrow) {
            arrow.style.opacity = 0;
        }

        let i = 0;
        const boxesInterval = setInterval(() => {
            createRandomBox(i%6);
            i++;
            if (i > 11) {
                clearInterval(boxesInterval);
            }
        }, 100);
      });


    // Scene
    const scene = new THREE.Scene()

    const lupoObjLoader = new OBJLoader()
    const mtlLoader = new MTLLoader();

    let meObj = null;

    // Loading models and put them into scene
    mtlLoader.load('/models/Me/me3.mtl', function (materials) {
        materials.preload();
        lupoObjLoader.setMaterials(materials);
        
        // Modify all loaded materials to set pixelated texture filtering
        for (const material of Object.values(materials.materials)) {
            // material.map = textureLoader.load('texture.png');
            material.map.magFilter = THREE.NearestFilter;
            material.map.minFilter = THREE.NearestFilter;
        }

        lupoObjLoader.load(
            // resource URL
            '/models/Me/me3.obj',
            // called when resource is loaded
            function ( object ) {
                meObj = object.children[0];
                object.position.set(0, 130, 0);
                scene.add( object );
            },
        );
    });

    /**
     * Physics
     */
    const world = new CANNON.World()
    world.broadphase = new CANNON.SAPBroadphase(world)
    world.allowSleep = true
    world.gravity.set(0, - 9.82, 0)

    // Default material
    const defaultMaterial = new CANNON.Material('default')
    const defaultContactMaterial = new CANNON.ContactMaterial(
        defaultMaterial,
        defaultMaterial,
        {
            friction: 0.1,
            restitution: 0.7
        }
    )
    world.defaultContactMaterial = defaultContactMaterial

    // Floor
    const floorShape = new CANNON.Plane()
    const floorBody = new CANNON.Body()
    floorBody.mass = 0
    floorBody.addShape(floorShape)
    floorBody.quaternion.setFromAxisAngle(new CANNON.Vec3(- 1, 0, 0), Math.PI * 0.5) 
    world.addBody(floorBody)

    /**
     * Utils
     */
    const objectsToUpdate = []

    // Create box
    const boxGeometry = new THREE.BoxGeometry(1, 1, 1)
    const textureLoader = new THREE.TextureLoader()
    const twitterTexture = textureLoader.load(
        '/textures/twitter.png',
    )
    const twitchTexture = textureLoader.load(
        '/textures/twitch.png',
    )
    const lolTexture = textureLoader.load(
        '/textures/lol.jpg',
    )
    const linkedinTexture = textureLoader.load(
        '/textures/linkedin.png',
    )
    const gmailTexture = textureLoader.load(
        '/textures/gmail.png',
    )
    const githubTexture = textureLoader.load(
        '/textures/github.png',
    )

    const textures = [twitterTexture, twitchTexture, lolTexture, linkedinTexture, gmailTexture, githubTexture];
    const colors = [ 0x1da1f2, 0x6441a4, 0x000000, 0xffffff, 0xffffff, 0xffffff];
    const names = [ 'twitter', 'twitch', 'lol', 'linkedin', 'gmail', 'github'];

    textures.forEach(tex => {
        tex.minFilter = THREE.NearestFilter
        tex.magFilter = THREE.NearestFilter
    });

    const createBox = (width, height, depth, position, type) =>
    {
        // Three.js mesh
        const materialArray = [
            new THREE.MeshBasicMaterial({ color: colors[type] }),
            new THREE.MeshBasicMaterial({ color: colors[type] }),
            new THREE.MeshBasicMaterial({ color: colors[type] }),
            new THREE.MeshBasicMaterial({ color: colors[type] }),
            new THREE.MeshBasicMaterial({ map: textures[type] }),
            new THREE.MeshBasicMaterial({ map: textures[type] }),
        ]
        const mesh = new THREE.Mesh(boxGeometry, materialArray)
        mesh.scale.set(width, height, depth)
        mesh.castShadow = true
        mesh.position.copy(position)
        mesh.name = names[type]
        // Set random initial rotation to mesh
        const meshQuaternion = new THREE.Quaternion().setFromEuler(
            new THREE.Euler(
            Math.random() * Math.PI * 2,
            Math.random() * Math.PI * 2,
            Math.random() * Math.PI * 2
            )
        );
        mesh.quaternion.copy(meshQuaternion);
    
        scene.add(mesh)

        // Cannon.js body
        const shape = new CANNON.Box(new CANNON.Vec3(width * 0.5, height * 0.5, depth * 0.5));

        const body = new CANNON.Body({
            mass: 1,
            position: new CANNON.Vec3(0, 3, 0),
            shape: shape,
            material: defaultMaterial,
        });
        body.position.copy(position);
        
        // Set random initial rotation to body
        const bodyQuaternion = new CANNON.Quaternion().setFromEuler(
            Math.random() * Math.PI * 2,
            Math.random() * Math.PI * 2,
            Math.random() * Math.PI * 2
        );
        body.quaternion.copy(bodyQuaternion);
        
        world.addBody(body);

        // Save in objects
        objectsToUpdate.push({ mesh, body });
    }

    /**
     * Floor
     */
    const floor = new THREE.Mesh(
        new THREE.PlaneGeometry(25, 25),
        new THREE.MeshStandardMaterial({
            color: '#ffffff',
            envMapIntensity: 0.5
        })
    )
    floor.receiveShadow = true
    floor.rotation.x = - Math.PI * 0.5
    scene.add(floor)

    /**
     * Lights
     */
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.7)
    scene.add(ambientLight)

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.2)
    directionalLight.castShadow = true
    directionalLight.shadow.mapSize.set(1024, 1024)
    directionalLight.shadow.camera.far = 15
    directionalLight.shadow.camera.left = - 7
    directionalLight.shadow.camera.top = 7
    directionalLight.shadow.camera.right = 7
    directionalLight.shadow.camera.bottom = - 7
    directionalLight.position.set(5, 5, 5)
    scene.add(directionalLight)

    /**
     * Sizes
     */
    const sizes = {
        width: window.innerWidth,
        height: 400
    }

    const initialWidth = window.innerWidth;
    let lastBodyHeight = document.body.scrollHeight;

    window.addEventListener('resize', () =>
    {
        if (document.body.scrollHeight !== lastBodyHeight) {
            lastBodyHeight = document.body.scrollHeight;
            if (!cameraUp) {
                canvas.style.top = document.body.scrollHeight - 400 + "px";
            }
        }

        // Update sizes
        sizes.width = window.innerWidth

        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()

        renderer.setSize(sizes.width, sizes.height)
        composer.setSize(sizes.width, sizes.height)
        
        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })

    /**
     * Camera
     */
    // Base camera
    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
    camera.Fa
    camera.position.set(-2, 2, 2)
    scene.add(camera)

    // Controls
    const controls = new OrbitControls(camera, canvas)
    controls.enableDamping = true
    controls.enablePan = false
    controls.enableZoom = false
    controls.maxPolarAngle = 1.5

    /**
     * Renderer
     */
    renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        alpha: true
    })

    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(1)

    const pixelPassOptions =  {
        normalEdgeStrength: 0,
        depthEdgeStrength: 5
    };
    const renderPixelatedPass = new RenderPixelatedPass(PIXEL_SIZE, scene, camera, pixelPassOptions);
    const composer = new EffectComposer(renderer);
    composer.addPass(renderPixelatedPass);

    /**
     * Mouse events
     */
    let intersects;
    let hovered;

    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    function onMouseMove(event) {
        startEvent();
        event.preventDefault();
        const canvasBounds = renderer.domElement.getBoundingClientRect();
        mouse.x = ((event.clientX - canvasBounds.left) / canvasBounds.width) * 2 - 1;
        mouse.y = -((event.clientY - canvasBounds.top) / canvasBounds.height) * 2 + 1;
    }

    let object;

    function updateHover() {
        raycaster.setFromCamera(mouse, camera);
        intersects = raycaster.intersectObject(scene, true);
        if (intersects.length > 0 && hovered) {
            if (object && object.id !== intersects[0].object.id && object.material && object.material.length > 0) {
                object.material.forEach(element => {
                    element.wireframe = false;
                });
            }
            if (intersects[0].object.geometry.type !== "PlaneGeometry" && intersects[0].object.material) {
                object = intersects[0].object;
                if (object.material.length > 0) {
                    object.material.forEach(element => {
                        element.wireframe = true;
                    });
                }
            }
        } else if (object && object.material && object.material.length > 0) {
            object.material.forEach(element => {
                element.wireframe = false;
            });
        }
    }

    function onClick() {
        if (intersects.length > 0 && intersects[0].object.geometry.type !== "PlaneGeometry") {
            const { name } = intersects[0].object;

            if (name === "twitter") {
                window.open('https://twitter.com/wojtus_7', '_blank').focus();
            } else if (name === "twitch") {
                window.open('https://www.twitch.tv/wojtus7', '_blank').focus();
            } else if (name === "linkedin") {
                window.open('https://www.linkedin.com/in/wojciech-stanisz-17a048160/', '_blank').focus();
            } else if (name === "github") {
                window.open('https://github.com/wojtus7', '_blank').focus();
            } else if (name === "gmail") {
                window.open('mailto:wojciech.piotr.stanisz@gmail.com');
            } else if (name === "lol") {
                window.open('https://www.op.gg/summoners/eune/wojtus7', '_blank').focus();
            }
        } 
    }

    function startEvent() {
        hovered = true;
    }

    function endEvent() {
        hovered = false;
    }

    canvas.addEventListener('mouseup', endEvent);
    canvas.addEventListener('mouseout', endEvent);
    canvas.addEventListener("mousemove", onMouseMove, false);
    canvas.addEventListener("click", onClick, false);

    /**
     * Animate
     */
    const clock = new THREE.Clock()
    let oldElapsedTime = 0

    const tick = () =>
    {
        const elapsedTime = clock.getElapsedTime()
        if (meObj) {
            // meObj.rotation.y = (Math.sin(elapsedTime * 1.2)) / 2; // Rotate mesh
            meObj.rotation.z = (Math.sin(elapsedTime * 1.2)) / 4; // Rotate mesh
        }
        
        updateHover();

        const deltaTime = elapsedTime - oldElapsedTime
        oldElapsedTime = elapsedTime

        // Update physics
        world.step(1 / 60, deltaTime, 3)
        
        for(const object of objectsToUpdate)
        {
            object.mesh.position.copy(object.body.position)
            object.mesh.quaternion.copy(object.body.quaternion)
        }

        // Update controls
        controls.update()

        // Render
        composer.render();

        // Call tick again on the next frame
        if (linksWrapper.innerHTML) {
            window.requestAnimationFrame(tick)
        }
        
    }

    tick()

const checkAndAdjustCamera = () => {
    if (window.scrollY < 1000 && !cameraUp) {
        canvas.style.top = "90px";
        cameraUp = true;
        controls.target.set(0, 131.7, 0)
        camera.position.set(0, 132.2, 3.3)
    controls.maxPolarAngle = 3
} else if (window.scrollY > 1000 && cameraUp) {
        canvas.style.top = lastBodyHeight - 500 + "px";
        cameraUp = false;
        controls.target.set(0, 0, 0)
        camera.position.set(-2, 2, 2)
    controls.maxPolarAngle = 1.5
}
}

document.addEventListener("scroll", checkAndAdjustCamera);

checkAndAdjustCamera();
