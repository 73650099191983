import * as THREE from 'three'
import { OrbitControls } from './OrbitControls.js'
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import { MTLLoader } from 'three/addons/loaders/MTLLoader.js';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPixelatedPass } from 'three/examples/jsm/postprocessing/RenderPixelatedPass.js';

// Scene
const scene = new THREE.Scene()

const lupoObjLoader = new OBJLoader()
const cityObjLoader = new OBJLoader()
const mtlLoader = new MTLLoader();

// Create skybox solid color
scene.background = new THREE.Color(0x300090); 

// Loading models and put them into scene
mtlLoader.load('/models/Lupo/lupo7.mtl', function (materials) {
    materials.preload();
    lupoObjLoader.setMaterials(materials);
    
    // Modify all loaded materials to set pixelated texture filtering
    for (const material of Object.values(materials.materials)) {
        // material.map = textureLoader.load('texture.png');
        material.map.magFilter = THREE.NearestFilter;
        material.map.minFilter = THREE.NearestFilter;
    }

    lupoObjLoader.load(
        // resource URL
        '/models/Lupo/lupo7.obj',
        // called when resource is loaded
        function ( object ) {
            object.rotation.y = 3.14 /2;
            object.position.x -= 2;
            object.position.z -= 1.2;

            scene.add( object );
        },
    );
});

mtlLoader.load('/models/Lupo/city1.mtl', function (materials) {
    materials.preload();
    cityObjLoader.setMaterials(materials);
    
    // Modify all loaded materials to set pixelated texture filtering
    for (const material of Object.values(materials.materials)) {
        // material.map = textureLoader.load('texture.png');
        material.map.magFilter = THREE.NearestFilter;
        material.map.minFilter = THREE.NearestFilter;
    }

    cityObjLoader.load(
        // resource URL
        '/models/Lupo/city1.obj',
        // called when resource is loaded
        function ( object ) {
            const geometry = object.children[0].geometry;
            const material = object.children[0].material;
            
            // Create multiple instances of the mesh
            const mesh1 = new THREE.Mesh(geometry.clone(), material.clone());
            mesh1.position.set(0, 0, 0);
            scene.add(mesh1);

            const mesh2 = new THREE.Mesh(geometry.clone(), material.clone());
            mesh2.position.set(17, 0, 0);
            scene.add(mesh2);

            const mesh3 = new THREE.Mesh(geometry.clone(), material.clone());
            mesh3.position.set(-17, 0, 0);
            scene.add(mesh3);
        },
    );
});


/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.8)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.camera.left = - 7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(- 5, 5, 0)
scene.add(directionalLight)

/**
 * Sizes
 */
const PIXEL_SIZE = window.innerWidth < 420 ? 3.5 : 4.5;
const initialPixelSizeSmall = window.innerWidth < 420;

const sizes = {
    width: window.innerWidth <= 420 ? 320 : 400,
    height: window.innerWidth <= 420 ? 320 : 400,
}

window.addEventListener('resize', () =>
{
    if (window.innerWidth <= 420 && sizes.width !== 320) {
        sizes.width = 320
        sizes.height = 320
        renderer1.setSize(sizes.width, sizes.height)
        composer1.removePass(renderPixelatedPass1);
        renderPixelatedPass1.pixelSize = 4;
        composer1.addPass(renderPixelatedPass1);
        
        renderer2.setSize(sizes.width, sizes.height)
        composer2.removePass(renderPixelatedPass2);
        renderPixelatedPass2.pixelSize = 4;
        composer2.addPass(renderPixelatedPass2);

        renderer3.setSize(sizes.width, sizes.height)
        composer3.removePass(renderPixelatedPass3);
        renderPixelatedPass3.pixelSize = 4;
        composer3.addPass(renderPixelatedPass3);
    } else if (window.innerWidth > 420 && sizes.width !== 400) {
        sizes.width = 400
        sizes.height = 400
        renderer1.setSize(sizes.width, sizes.height)
        composer1.removePass(renderPixelatedPass1);
        renderPixelatedPass1.pixelSize = initialPixelSizeSmall ? 3.5 : 4.;
        composer1.addPass(renderPixelatedPass1);

        renderer2.setSize(sizes.width, sizes.height)
        composer2.removePass(renderPixelatedPass2);
        renderPixelatedPass2.pixelSize = initialPixelSizeSmall ? 3.5 : 4.;
        composer2.addPass(renderPixelatedPass2);

        renderer3.setSize(sizes.width, sizes.height)
        composer3.removePass(renderPixelatedPass3);
        renderPixelatedPass3.pixelSize = initialPixelSizeSmall ? 3.5 : 4.;
        composer3.addPass(renderPixelatedPass3);
}
})

/**
 * Camera
 */
// Canvas
const canvas1 = document.querySelector('canvas.lupo1')
const canvas2 = document.querySelector('canvas.lupo2')
const canvas3 = document.querySelector('canvas.lupo3')

// Base camera
const camera1 = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
const camera2 = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
const camera3 = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera1.position.set(1.4, 0.7, 0.8)
camera2.position.set(2, 1.3, -3)
camera3.position.set(-4.6, 2, -3.5)
scene.add(camera1)
scene.add(camera2)
scene.add(camera3)

// Controls
const controls1 = new OrbitControls(camera1, canvas1)
controls1.target.set(0.2, 0.75, 0)
controls1.enableDamping = true
controls1.maxAzimuthAngle = 2
controls1.minAzimuthAngle = 0.4
controls1.maxPolarAngle = 1.7
controls1.minDistance = 0.2
controls1.maxDistance = 2.3
controls1.enablePan = false
controls1.enableZoom = false

const controls2 = new OrbitControls(camera2, canvas2)
controls2.target.set(-1, 0.75, -1)
controls2.enableDamping = true
controls2.maxAzimuthAngle = 4
controls2.minAzimuthAngle = 1.5
controls2.maxPolarAngle = 1.65
controls2.minDistance = 2.1
controls2.maxDistance = 6
controls2.enablePan = false
controls2.enableZoom = false

const controls3 = new OrbitControls(camera3, canvas3)
controls3.target.set(-2, 0, -1)
controls3.enableDamping = true
controls3.maxAzimuthAngle = 5.1
controls3.minAzimuthAngle = 3.14
controls3.maxPolarAngle = 1.5
controls3.minDistance = 2.8
controls3.maxDistance = 6
controls3.enablePan = false
controls3.enableZoom = false

/**
 * Renderer
 */
const renderer1 = new THREE.WebGLRenderer({
    canvas: canvas1,
})
const renderer2 = new THREE.WebGLRenderer({
    canvas: canvas2,
})
const renderer3 = new THREE.WebGLRenderer({
    canvas: canvas3,
})
const context1 = renderer1.getContext();
const context2 = renderer2.getContext();
const context3 = renderer3.getContext();

context1.canvas.addEventListener('webglcontextlost', event => {
    canvas1.style.zIndex = 0;
}, false);
context2.canvas.addEventListener('webglcontextlost', event => {
    canvas2.style.zIndex = 0;
}, false);
context3.canvas.addEventListener('webglcontextlost', event => {
    canvas3.style.zIndex = 0;
}, false);

const renderers = [renderer1, renderer2, renderer3]

renderers.forEach((r) => {
    r.shadowMap.enabled = true
    r.shadowMap.type = THREE.PCFSoftShadowMap
    r.setSize(sizes.width, sizes.height)
    r.setPixelRatio(1)
})

const pixelPassOptions =  {
    normalEdgeStrength: 0,
    depthEdgeStrength: 5
};
const renderPixelatedPass1 = new RenderPixelatedPass(PIXEL_SIZE, scene, camera1, pixelPassOptions);
const renderPixelatedPass2 = new RenderPixelatedPass(PIXEL_SIZE, scene, camera2, pixelPassOptions);
const renderPixelatedPass3 = new RenderPixelatedPass(PIXEL_SIZE, scene, camera3, pixelPassOptions);
const composer1 = new EffectComposer(renderer1);
const composer2 = new EffectComposer(renderer2);
const composer3 = new EffectComposer(renderer3);
composer1.addPass(renderPixelatedPass1);
composer2.addPass(renderPixelatedPass2);
composer3.addPass(renderPixelatedPass3);

/**
 * Animate
 */
const tick = () =>
{
    // Update controls
    controls1.update()
    controls2.update()
    controls3.update()

    // Render
    composer1.render();
    composer2.render();
    composer3.render();

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()